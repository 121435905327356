<template>
    <div>
        <div class="bg">
            <div class="tit">
                {{ meg.title }}
            </div>
            <div class="time">
                {{ meg.words }} · {{ meg.month }}{{ meg.year }}
            </div>
            <div class="photo">
                <img v-lazy=meg.newsImg alt="" style="width: 100%;height: 724px;border-radius: 10px;">
            </div>
        </div>
        <div class="wrap">
                <div style="margin: 0 auto;" v-html="meg.content ? meg.content : ''"></div>
            <el-divider></el-divider>
            <div style="font-size: 24px;font-weight: bold;color: #000;">Recommended Post</div>
            <div class="other">
                <div class="others" v-for="item in meg.news" :key="item.id">
                    <div @click="detail(item.id)">
                     <img v-lazy="item.newsImg" alt="" style="width: 100%;height: 199px;border-radius: 10px;">
                    <div class="othertitle">
                        {{ item.title }}
                    </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            meg: [],
        }
    },
    activated() {
        this.getdetails()
    },
    methods: {
        getdetails() {
            this.$axios
                .post("/api/mall/newsDetailed/detailed", {
                    parentId: this.$route.query.id
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.meg = res.data.data
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        detail(id) {
            this.$router.push({
                path: '/eventscenter',
                query: {
                    id: id
                }
            })
            this.getdetails()
        },
    }
}
</script>

<style scoped>
.bg {
    height: 339px;
    background-color: #7A060B;
    padding-top: 88px;
    position: relative;
    margin-bottom: 656px;
}

.tit {
    color: #FFF;
    font-weight: bold;
    font-size: 36px;
    display: flex;
    justify-content: center;
}

.time {
    margin-top: 14px;
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.photo {
    width: 1280px;
    height: 724px;
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrap {
    width: 1280px;
    margin: 0 auto;
}

.other {
    margin: 38px 0 79px;
    display: flex;
    justify-content: space-between;
}
.others{
    width: 300px;
    height: 251px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
}
.othertitle{
    margin-top: 17px;
    color: #333;
    display: flex;
    justify-content: center;
}
</style>